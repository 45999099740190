// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useLocalParticipant } from '@livekit/components-react';
import { styled } from '@mui/material';
import { Track } from 'livekit-client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserChoices } from '../../provider/UserChoicesProvider';
import AudioButton from './fragments/AudioButton';
import EndCallButton from './fragments/EndCallButton';
import HandraiseButton from './fragments/HandraiseButton';
import MoreButton from './fragments/MoreButton';
import ShareScreenButton from './fragments/ShareScreenButton';
import VideoButton from './fragments/VideoButton';

type LayoutTypes = 'fullscreen';
export enum ToolbarButtonIds {
  Handraise = 'toolbar-handraise',
  ShareScreen = 'toolbar-share-screen',
  Audio = 'toolbar-audio',
  Video = 'toolbar-video',
  More = 'toolbar-more',
  EndCall = 'toolbar-endcall',
}
const MainContainer = styled('aside')(({ theme }) => ({
  display: 'flex',
  background: 'transparent',
  justifyContent: 'space-evenly',
  padding: theme.spacing(2, 0),
  '&.fullscreen': {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: theme.spacing(1.25),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(1),
    background: theme.palette.background.paper,
  },
  [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
    gap: theme.spacing(1),
    background: theme.palette.background.paper,
  },
}));

const Toolbar = ({ layout }: { layout?: LayoutTypes }) => {
  const { t } = useTranslation();
  const choices = useUserChoices();
  const isUserAudioEnabled = Boolean(choices?.userChoices.audioEnabled) || false;
  const isUserVideoEnabled = Boolean(choices?.userChoices.videoEnabled) || false;

  const { localParticipant } = useLocalParticipant();

  useEffect(() => {
    localParticipant.setMicrophoneEnabled(isUserAudioEnabled);
  }, [localParticipant, isUserAudioEnabled]);

  const audioTrack = localParticipant.getTrackPublication(Track.Source.Microphone)?.audioTrack;

  return (
    <MainContainer aria-label={t('landmark-complementary-toolbar')} className={layout} data-testid="Toolbar">
      <HandraiseButton />
      <ShareScreenButton />
      <AudioButton microphoneEnabled={isUserAudioEnabled || false} track={audioTrack} />
      <VideoButton videoEnabled={isUserVideoEnabled} />
      <MoreButton />
      <EndCallButton />
    </MainContainer>
  );
};

export default Toolbar;
