// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useLocalParticipant, useMediaDeviceSelect } from '@livekit/components-react';
import {
  Avatar,
  Divider,
  FormGroup,
  ListItemText,
  MenuList,
  FormControlLabel as MuiFormControlLabel,
  MenuItem as MuiMenuItem,
  Stack,
  Switch,
  ThemeProvider,
  Typography,
  styled,
} from '@mui/material';
import { LocalVideoTrack } from 'livekit-client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CameraOnIcon, CloseIcon, ErrorIcon, WarningIcon } from '../../../assets/icons';
import { createOpenTalkTheme } from '../../../assets/themes/opentalk';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useFullscreenContext } from '../../../hooks/useFullscreenContext';
import browser from '../../../modules/BrowserSupport';
import { DeviceId } from '../../../modules/Media/MediaUtils';
import { useUserChoices } from '../../../provider/UserChoicesProvider';
import { selectVideoBackgrounds } from '../../../store/slices/configSlice';
import { selectVideoBackgroundEffects, setBackgroundEffects } from '../../../store/slices/mediaSlice';
import { mirroredVideoSet, selectMirroredVideoEnabled } from '../../../store/slices/uiSlice';
import DeviceList from './DeviceList';
import { MenuSectionTitle, ToolbarMenu, ToolbarMenuProps } from './ToolbarMenuUtils';

// const SliderContainer = styled(ListItem)(({ theme }) => ({
//   background: theme.palette.secondary.lightest,
//   borderRadius: theme.borderRadius.medium,
//   margin: theme.spacing(0, 2),
//   width: 'auto',
// }));

const MenuItem = styled(MuiMenuItem)({
  '&.MuiMenuItem-root:hover': {
    backgroundColor: 'transparent',
  },
});

const FormControlLabel = styled(MuiFormControlLabel)({
  flex: 1,
  margin: 0,
  justifyContent: 'space-between',
});

const BackgroundOptionsContainer = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(0.5, 1.5),
}));

const VideoBackgroundImage = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
  aspectRatio: '16/9',
  height: '3.5rem',
  width: 'auto',
  cursor: 'pointer',
  borderRadius: active ? theme.borderRadius.small : 0,
  outline: ` ${active ? '3px' : 0} solid ${theme.palette.warning.main}`,
}));

const BackgroundImageList = styled(MenuList)(({ theme }) => ({
  margin: theme.spacing(1, 2, 0),
  display: 'grid',
  justifyContent: 'space-evenly',
  gridTemplateColumns: 'repeat(3, auto)',
  gridGap: theme.spacing(2),
}));

const BackgroundImageItem = styled(MenuItem)(({ theme }) => ({
  width: 'fit-content',
  padding: 0,
  '&.Mui-focusVisible': {
    '& > .MuiAvatar-root': {
      outline: `solid ${theme.palette.primary.main}`,
    },
  },
}));

const ClearBackground = styled(VideoBackgroundImage)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.secondary.lightest,
  color: theme.palette.secondary.main,
}));

const MultilineTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
});

interface VideoMenuProps extends ToolbarMenuProps {
  permissionDenied: boolean | null;
  videoEnabled: boolean;
  isLobby: boolean;
}

const VideoMenu = ({ anchorEl, onClose, open, permissionDenied, isLobby }: VideoMenuProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const fullscreenHandle = useFullscreenContext();
  const choices = useUserChoices();
  const { cameraTrack } = useLocalParticipant();
  const localVideoTrack = cameraTrack?.track as LocalVideoTrack | undefined;

  const backgroundEffects = useAppSelector(selectVideoBackgroundEffects);
  const mirroringEnabled = useAppSelector(selectMirroredVideoEnabled);
  const videoBackgrounds = useAppSelector(selectVideoBackgrounds);

  const [requestPermissions, setRequestPermissions] = useState(false);

  const { devices } = useMediaDeviceSelect({
    kind: 'videoinput',
    requestPermissions,
  });

  const filteredDevices = useMemo(() => devices.filter((device) => device.deviceId !== ''), [devices]);

  const selectedDeviceId = choices?.userChoices.videoDeviceId;

  const isBrowserSafariOrFireFox = browser.isSafari() || (browser.isFirefox() && isLobby);

  const isBlurred = backgroundEffects.style === 'blur';

  const setBlur = useCallback(
    (enabled: boolean) => {
      dispatch(setBackgroundEffects({ style: enabled ? 'blur' : 'off' }));
    },
    [dispatch]
  );

  const setImageBackground = useCallback(
    (imageUrl: string) => {
      dispatch(setBackgroundEffects({ style: 'image', imageUrl }));
    },
    [dispatch]
  );

  const toggleMirroring = useCallback(
    () => dispatch(mirroredVideoSet(!mirroringEnabled)),
    [dispatch, mirroringEnabled]
  );

  useEffect(() => {
    if (open && !requestPermissions) {
      setRequestPermissions(true);
    }
  }, [open, requestPermissions]);

  useEffect(() => {
    if (choices?.userChoices.videoDeviceId === '' && filteredDevices[0]?.deviceId) {
      choices.saveVideoInputDeviceId(filteredDevices[0].deviceId);
    }

    if (
      choices?.userChoices.videoDeviceId &&
      !filteredDevices.some((device) => device.deviceId === choices.userChoices.videoDeviceId) &&
      filteredDevices[0]?.deviceId
    ) {
      choices.saveVideoInputDeviceId(filteredDevices[0].deviceId);
    }
  }, [filteredDevices, choices]);

  // We need this mapping for screen readers to read slider labels properly
  // A function passed to the getAriaValueText prop of the Slider must have signature
  // (number) => string
  // const getSliderLabel = (value: VideoQuality) => {
  //   switch (value) {
  //     // case VideoQuality.OFF:
  //     //   return t('quality-audio-only');

  //     case VideoQuality.LOW:
  //       return t('quality-low');

  //     case VideoQuality.MEDIUM:
  //       return t('quality-medium');

  //     case VideoQuality.HIGH:
  //       return t('quality-high');

  //     default:
  //       return 'Unknown slider value';
  //   }
  // };

  // const room = useMaybeRoomContext();

  // const qualityMarks = [
  //   // {
  //   //   value: VideoQuality.OFF,
  //   //   label: getSliderLabel(VideoQuality.OFF),
  //   // },
  //   {
  //     value: VideoQuality.LOW,
  //     label: getSliderLabel(VideoQuality.LOW),
  //   },
  //   {
  //     value: VideoQuality.MEDIUM,
  //     label: getSliderLabel(VideoQuality.MEDIUM),
  //   },
  //   {
  //     value: VideoQuality.HIGH,
  //     label: getSliderLabel(VideoQuality.HIGH),
  //   },
  // ];

  const handleClick = (deviceId: DeviceId) => {
    if (deviceId !== selectedDeviceId) {
      choices?.saveVideoInputDeviceId(deviceId);

      localVideoTrack?.setDeviceId(deviceId).catch((e) => {
        console.error('error while setting deviceId: ', e);
      });
    }
  };

  return (
    <ThemeProvider theme={createOpenTalkTheme()}>
      <ToolbarMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disablePortal={fullscreenHandle.active}
        id="video-context-menu"
        aria-labelledby="video-menu-title"
        role="menu"
      >
        <MenuSectionTitle id="video-menu-title" sx={{ pt: 1.5, pb: 1.5 }}>
          <CameraOnIcon />
          {t('videomenu-choose-input')}
        </MenuSectionTitle>
        {permissionDenied && (
          <MenuSectionTitle>
            <ErrorIcon />
            <MultilineTypography variant="body2">{t('device-permission-denied')}</MultilineTypography>
          </MenuSectionTitle>
        )}
        {/* TODO: livekit - {devices === undefined || !mediaContext.hasAllVideoDetails ? ( */}
        {filteredDevices.length === 0 ? (
          <MenuSectionTitle>
            <WarningIcon />
            <ListItemText>{t('devicemenu-wait-for-permission')}</ListItemText>
          </MenuSectionTitle>
        ) : (
          <DeviceList
            devices={filteredDevices.sort((a, b) => a.label.localeCompare(b.label))}
            selectedDevice={selectedDeviceId as DeviceId | undefined}
            onClick={(deviceId: DeviceId) => handleClick(deviceId)}
            ariaLabelId="video-menu-title"
          />
        )}
        {/* <Divider variant="middle" /> */}
        {/* <MenuSectionTitle>
          <SettingsIcon />
          {t('videomenu-settings')}
        </MenuSectionTitle>
        <Typography fontWeight="normal" id="quality-slider" sx={{ pt: 1, pb: 2, px: 2 }}>
          {t('quality-cap-setting')}
        </Typography>
        <SliderContainer sx={{ px: 3 }}>
          <Slider
            value={qualityCap}
            onChangeCommitted={(ev, value) => {
              setQualityCap(value as VideoQuality);

              room?.remoteParticipants.forEach((participant) => {
                participant.videoTrackPublications.forEach((track) => {
                  track.setVideoQuality(value as VideoQuality);
                });
              });
            }}
            aria-labelledby="quality-slider"
            valueLabelDisplay="off"
            step={1}
            marks={qualityMarks}
            min={VideoQuality.LOW}
            // min={VideoQuality.OFF}
            max={VideoQuality.HIGH}
            getAriaValueText={(value) => getSliderLabel(value)}
          />
        </SliderContainer> */}
        <Divider variant="middle" />
        <MenuSectionTitle>{t('videomenu-background')}</MenuSectionTitle>
        <FormGroup>
          <BackgroundOptionsContainer spacing={1}>
            {!isBrowserSafariOrFireFox && (
              <FormControlLabel
                control={<Switch onChange={(_, enabled) => setBlur(enabled)} value={isBlurred} checked={isBlurred} />}
                label={
                  <Typography fontWeight="normal">
                    {t(isBlurred ? 'videomenu-blur-on' : 'videomenu-blur-off')}
                  </Typography>
                }
                labelPlacement="start"
              />
            )}
            <FormControlLabel
              control={<Switch onChange={toggleMirroring} value={mirroringEnabled} checked={mirroringEnabled} />}
              label={
                <Typography fontWeight="normal">
                  {t(mirroringEnabled ? 'videomenu-mirroring-on' : 'videomenu-mirroring-off')}
                </Typography>
              }
              labelPlacement="start"
            />
          </BackgroundOptionsContainer>
        </FormGroup>

        {/* TODO: livekit - change back to isBrowserSafariOrFireFox after fixing background image for firefox */}
        {!(browser.isSafari() || browser.isFirefox()) && videoBackgrounds.length > 0 && (
          <>
            <Divider variant="middle" />
            <Typography fontWeight="normal" id="background-images-title" sx={{ px: 2 }}>
              {t('videomenu-background-images')}
            </Typography>
            <BackgroundImageList aria-labelledby="background-images-title" role="listbox">
              <BackgroundImageItem onClick={() => setBlur(false)} aria-label={t('videomenu-background-no-image')}>
                <ClearBackground variant="square" active={backgroundEffects.style === 'off'}>
                  <CloseIcon />
                </ClearBackground>
              </BackgroundImageItem>
              {videoBackgrounds.map((image) => {
                const selectedEnabled = backgroundEffects.imageUrl === image.url;
                return (
                  <BackgroundImageItem
                    key={image.url}
                    onClick={() => (!selectedEnabled ? setImageBackground(image.url) : setBlur(false))}
                    aria-label={image.altText}
                    // TODO: livekit
                    // disabled={localVideoTrack?.getProcessor()?.processedTrack?.readyState === undefined}
                  >
                    <VideoBackgroundImage
                      src={image.thumb}
                      key={image.url}
                      alt={image.altText}
                      variant="square"
                      active={selectedEnabled}
                    />
                  </BackgroundImageItem>
                );
              })}
            </BackgroundImageList>
          </>
        )}
      </ToolbarMenu>
    </ThemeProvider>
  );
};

export default VideoMenu;
