// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useLocalParticipant, useRoomContext } from '@livekit/components-react';
import { BackgroundBlur as LivekitBlur, ProcessorWrapper, VirtualBackground } from '@livekit/track-processors';
import { ConnectionState, LocalVideoTrack, VideoCaptureOptions } from 'livekit-client';
import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../../hooks';
import { BackgroundBlur } from '../../modules/Media/BackgroundBlur';
import { useUserChoices } from '../../provider/UserChoicesProvider';
import { selectVideoBackgroundEffects } from '../../store/slices/mediaSlice';

export const BLUR_RADIUS = 10;

export const useMediaManager = (isLobby?: boolean) => {
  const choices = useUserChoices();
  const { localParticipant } = useLocalParticipant();
  const room = useRoomContext();
  const backgroundEffects = useAppSelector(selectVideoBackgroundEffects);

  const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack | undefined>(undefined);
  const [fallbackBlurProcessor, setFallbackBlurProcessor] = useState<BackgroundBlur | null>(null);

  const isBlurred = backgroundEffects.style === 'blur';
  const virtualBackgroundActive = backgroundEffects.style === 'image';
  const imageUrl = backgroundEffects.imageUrl;
  const isUserVideoEnabled = Boolean(choices?.userChoices.videoEnabled) || false;
  const deviceId = choices?.userChoices.videoDeviceId;

  useEffect(() => {
    const isRoomConnected = room.state !== ConnectionState.Disconnected;
    const processor = localVideoTrack?.getProcessor();

    let videoCaptureOptions: VideoCaptureOptions = {
      deviceId,
    };

    if (ProcessorWrapper.isSupported) {
      if (isBlurred && (!processor || processor?.name !== 'background-blur')) {
        videoCaptureOptions = {
          ...videoCaptureOptions,
          processor: LivekitBlur(10),
        };
      } else if (virtualBackgroundActive && imageUrl) {
        videoCaptureOptions = {
          ...videoCaptureOptions,
          processor: VirtualBackground(imageUrl),
        };
      }
    }

    !isLobby &&
      isRoomConnected &&
      localParticipant.setCameraEnabled(isUserVideoEnabled, videoCaptureOptions).then((publication) => {
        const videoTrack = publication?.videoTrack;
        setLocalVideoTrack(videoTrack);

        if (videoTrack) {
          if (room.state === ConnectionState.Connected || room.state === ConnectionState.Connecting) {
            localParticipant.publishTrack(videoTrack);
          }
        }
      });
  }, [
    deviceId,
    isLobby,
    isUserVideoEnabled,
    localParticipant.setCameraEnabled,
    localParticipant.publishTrack,
    room.state,
    imageUrl,
    isBlurred,
    virtualBackgroundActive,
    localVideoTrack?.getProcessor,
  ]);

  const applyBackgroundEffect = useCallback(
    async (localVideoTrack: LocalVideoTrack) => {
      const processor = localVideoTrack?.getProcessor();

      if (isBlurred && (!processor || processor?.name !== 'background-blur')) {
        await localVideoTrack.setProcessor(LivekitBlur(BLUR_RADIUS));
      } else if (virtualBackgroundActive && imageUrl) {
        await localVideoTrack.setProcessor(VirtualBackground(imageUrl));
      } else if (backgroundEffects.style === 'off' && processor?.name) {
        await localVideoTrack.stopProcessor();
      }
    },
    [isBlurred, imageUrl, backgroundEffects.style, virtualBackgroundActive]
  );

  const handleFallbackBlur = useCallback(
    async (localVideoTrack: LocalVideoTrack) => {
      if ((isBlurred || virtualBackgroundActive) && localVideoTrack && !fallbackBlurProcessor) {
        const blurProcessor = await BackgroundBlur.create();
        setFallbackBlurProcessor(blurProcessor);

        const blurredTrack = await blurProcessor.start(localVideoTrack.mediaStreamTrack, backgroundEffects);
        if (localVideoTrack?.sender) {
          await localVideoTrack.replaceTrack(blurredTrack);
        } else {
          console.warn('Track is unpublished, unable to replace the track');
        }
      } else if (backgroundEffects.style === 'off' && fallbackBlurProcessor) {
        const newTrack = await localParticipant.createTracks({ video: { deviceId } });
        if (newTrack.length > 0) localVideoTrack?.replaceTrack(newTrack[0].mediaStreamTrack);
        setFallbackBlurProcessor(null);
      }
    },
    [backgroundEffects, isBlurred, localParticipant, fallbackBlurProcessor, virtualBackgroundActive, deviceId]
  );

  useEffect(() => {
    const handleBackgroundEffect = async () => {
      if (!localVideoTrack) return;

      if (ProcessorWrapper.isSupported) {
        await applyBackgroundEffect(localVideoTrack);
      } else {
        await handleFallbackBlur(localVideoTrack);
      }
    };

    if (!localVideoTrack?.isMuted) handleBackgroundEffect();
  }, [applyBackgroundEffect, handleFallbackBlur, localVideoTrack, localVideoTrack?.isMuted]);

  return {
    localVideoTrack,
  };
};
