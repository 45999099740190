// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Room } from 'livekit-client';

import { RootState } from '../';
import { hangUp, joinSuccess } from '../commonActions';

let livekitRoom: Room | undefined;

export const setLivekitRoom = (room: Room | undefined) => {
  livekitRoom = room;
};

export const getLivekitRoom = (): Room | undefined => {
  return livekitRoom;
};

interface LivekitState {
  livekitUnavailable: boolean;
  livekitAccessToken: string | undefined;
}

const initialState: LivekitState = {
  livekitUnavailable: false,
  livekitAccessToken: undefined,
};

export const livekitSlice = createSlice({
  name: 'livekit',
  initialState,
  reducers: {
    setLivekitUnavailable: (state, { payload }: PayloadAction<boolean>) => {
      state.livekitUnavailable = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(joinSuccess, (state, { payload }) => {
      state.livekitAccessToken = payload.livekit.token;
    });
    builder.addCase(hangUp.pending, (state) => {
      state.livekitAccessToken = undefined;
    });
    builder.addCase(hangUp.fulfilled, (state) => {
      state.livekitAccessToken = undefined;
    });
    builder.addCase(hangUp.rejected, (state) => {
      state.livekitAccessToken = undefined;
    });
  },
});

export const { setLivekitUnavailable } = livekitSlice.actions;

export const selectLivekitUnavailable = (state: RootState) => state.livekit.livekitUnavailable;
export const selectLivekitAccessToken = (state: RootState) => state.livekit.livekitAccessToken;

export default livekitSlice.reducer;
